<template>
  <v-app>
    <v-app-bar :color="alert.type" dark v-if="alert" app>
      <v-toolbar-title>{{alert.message}}</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    loading() {
      return !!this.$store.state.apiRequestCounter
    },
    alert() {
      return this.$store.getters.alert
    }
  }
}
</script>
